
<template>
<AppHeader />
         <!--Content start-->
        <div class="container">
            <div class="row">
                <div class="col-12">
      
        <div class="cssloader">
    <div class="sh1"></div>
    <div class="sh2"></div>
    
    <h4 class="lt">الرجاء الانتظار سيتم تحويلك تلقائياً</h4>
</div>
    
                </div>
            </div>
        </div>
    <AppFooter/>
</template>

<script>
import AppHeader from "../Include/AppHeader.vue";
import AppFooter from "../Include/AppFooter.vue";
import { useToast } from "vue-toastification";
import axios from "axios";
export default {
  components: { AppFooter,AppHeader},
  name: "HE_Check",
  data() {
    return {
      // someData: [],
      // serviceCode: "6fb3d058-a364-43a8-94fb-570659366919"
    };
  },

  mounted() {
    // let loader = this.$loading.show({
    //     // Optional parameters
    //     container: this.otp ? null : this.$refs.formContainer,
    //     // canCancel: true,
    //     // onCancel: this.onCancel,
    // });
        // simulate AJAX
        // setTimeout(() => {
        // loader.hide()
        // }, 5000)
    let formData = new FormData();
    formData.append("serviceCode", "bf7190b3-1d95-453f-ac17-4fa9abb9e996");
    axios
      .post("http://196.1.241.110/SPayHeaderEnrichment/index.php", formData, {})
      .then((response) => {
        // console.log(response.data);
        // this.someData = response.request.response;
        const oldString = response.data;
        // console.log(oldString);
        const responseCode = oldString.match(
          /responseCode" value="(.*?)">/i
        )[1];
          //  console.log("responseCode 111111111111111111 :"+ responseCode)
        // const status = oldString.match(/status" value="(.*?)">/i)[1];
        //    console.log("status :"+ status)
        const msisdn = oldString.match(/msisdn" value="(.*?)">/i)[1];


        if (msisdn != "") {
          // this.$cookie.setCookie("responseCode", responseCode);
          // this.$cookie.setCookie("status", status);
          // this.$cookie.setCookie("msisdn", msisdn);
          const toast = useToast();

          if (responseCode == 1) {
            axios
              .get(
                "CheckLogin.php?username=" +
                  msisdn +
                  "&serviceId=2222&operatorID=2"
              )
              .then((response) => {
                if (
                  response.data.status == 1 &&
                  response.data.responseCode == 1
                ) {
                  toast.success("تم اشتراكك بنجاح في منصة نشوف", {
                    timeout: 4000,
                  });
                  this.$cookie.setCookie("status", response.data.status, {
                    expire: 60 * response.data.MINTUES,
                  });
                  this.$cookie.setCookie("mdn", msisdn, {
                      expire: 60 * response.data.MINTUES,
                    }
                  );
                  this.$cookie.setCookie("minutes", response.data.MINTUES, {
                    expire: 60 * response.data.MINTUES,
                  });

                  if(this.$cookie.isCookieAvailable("Content_id")) {
                    setTimeout(() => this.$router.push({ path: "/content" }), 3000);
                  } else {
                    setTimeout(() => this.$router.push({ path: "/" }), 3000);
                  } 
              
                } 
              });
            // this.$router.push("Subscribe");
          } else if(responseCode == 111) {
            axios
              .get(
                "CheckLogin.php?username=" +
                  msisdn +
                  "&serviceId=2222&operatorID=2"
              )
              .then((response) => {
                if (
                  response.data.status == 1 &&
                  response.data.responseCode == 1
                ) {
                  toast.success("انت مشترك مسبقا تم تسجيل دخولك بنجاح", {
                    timeout: 5000,
                  });
                  this.$cookie.setCookie("status", response.data.status, {
                    expire: 60 * response.data.MINTUES,
                  });
                  this.$cookie.setCookie("mdn", msisdn, {
                      expire: 60 * response.data.MINTUES,
                    }
                  );
                  this.$cookie.setCookie("minutes", response.data.MINTUES, {
                    expire: 60 * response.data.MINTUES,
                  });
                  if(this.$cookie.isCookieAvailable("Content_id")) {
                    setTimeout(() => this.$router.push({ path: "/content" }), 3000);
                  } else {
                    setTimeout(() => this.$router.push({ path: "/" }), 3000);
                  } 
                   
                  // this.$router.push("/");
              
                } else if (
                  response.data.status == 0 &&
                  response.data.responseCode == 118
                ) {
                  toast.info("عفوا لم يكتمل الاشتراك ، قم بتغذية رصيدك", {
                    timeout: 5000,
                  });
                  
                  if(this.$cookie.isCookieAvailable("Content_id")) {
                    this.$cookie.removeCookie("Content_id");
                    setTimeout(() => this.$router.push({ path: "/" }), 3000);
                  } else {
                    setTimeout(() => this.$router.push({ path: "/" }), 3000);
                  } 
                   
                  // this.$router.push("/");
              
                } 
              });
          } else {
            axios
              .get(
                "CheckLogin.php?username=" +
                  msisdn +
                  "&serviceId=2222&operatorID=2"
              )
              .then((response) => {
                if (
                  response.data.status == 1 &&
                  response.data.responseCode == 1
                ) {
                  toast.success("انت مشترك مسبقا تم تسجيل دخولك بنجاح", {
                    timeout: 4000,
                  });
                  this.$cookie.setCookie("status", response.data.status, {
                    expire: 60 * response.data.MINTUES,
                  });
                  this.$cookie.setCookie("mdn", msisdn, {
                      expire: 60 * response.data.MINTUES,
                    }
                  );
                  this.$cookie.setCookie("minutes", response.data.MINTUES, {
                    expire: 60 * response.data.MINTUES,
                  });
                  if(this.$cookie.isCookieAvailable("Content_id")) {
                    setTimeout(() => this.$router.push({ path: "/content" }), 3000);
                  } else {
                    setTimeout(() => this.$router.push({ path: "/" }), 3000);
                  } 
              
                }else
                {
                  //  toast.info("حاول الاشتراك اخري" , {
                  //     timeout: 3000
                  //  });
                   this.$router.push("/signup");
                }

              });
          }
        } else {
          // if(this.$cookie.isCookieAvailable("Content_id")) {
          //   console.log("Content_id is :"+this.$cookie.getCookie("Content_id"));
          //           setTimeout(() => this.$router.push({ path: "/content" }), 3000);
          //         } else {
          //           setTimeout(() => this.$router.push({ path: "/" }), 3000);
          //         } 
          this.$router.push("/signup");
        }
      });
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@300&family=Raleway:wght@300&family=Roboto:ital,wght@1,300&display=swap");
            

            h3 {
                font-family: 'Cairo', sans-serif;
                font-weight:600;
                letter-spacing: 2px;
                font-size:32px;
            }
            p {
                font-family: 'Cairo', sans-serif;
                letter-spacing: 0.1px;
                font-size:14px;
                color: #333333;
            }

            
            .inner-header img{
                width:15%;
                position: relative;
                margin-top: 6%;

            }


            .inner-header {
                height:20vh;
                width:100%;
                margin: 0;
                padding: 0;
            }

            .flex { /*Flexbox for containers*/
                display: flex;
                flex-direction:column;
                justify-content: center;
                align-items: center;
                text-align: center;
            }

            .bottom-container{
                display:flex;
                align-items:center;
            }

            a{
                text-decoration:none;
                margin: 0px 5px;
                font-family: 'Cairo', sans-serif;
                letter-spacing:0px;
                font-size:14px;
                font-weight:600;
                color: #ffffff;

            }

            .waves {
                position:relative;
                width: 100%;
                height:15vh;
                margin-bottom:-7px; /*Fix for safari gap*/
                min-height:100px;
                max-height:150px;
            }

            .content {
                position:relative;
                height:100%;
                text-align:center;
                background-color: white;
            }

            /* Animation */

            .parallax > use {
                animation: move-forever 25s cubic-bezier(.55,.5,.45,.5)     infinite;
            }
            .parallax > use:nth-child(1) {
                animation-delay: -2s;
                animation-duration: 7s;
            }
            .parallax > use:nth-child(2) {
                animation-delay: -3s;
                animation-duration: 10s;
            }
            .parallax > use:nth-child(3) {
                animation-delay: -4s;
                animation-duration: 13s;
            }
            .parallax > use:nth-child(4) {
                animation-delay: -5s;
                animation-duration: 20s;
            }
            @keyframes move-forever {
                0% {
                    transform: translate3d(-90px,0,0);
                }
                100% { 
                    transform: translate3d(85px,0,0);
                }
            }
            
            
            

            form{
                display:flex;
                width:90%;
                justify-content:center;
                align-items:center;
                flex-direction:column;
                padding:10px;
                margin:10%;
            }

            input{
                width:40%;
                padding:8px;
                margin:10px;
                height:45px;
                font-size:1rem;;
                border:none;
                box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 20%);
                border-radius:12px;
                outline:none;
                text-align:center;
                font-family: 'Cairo', sans-serif;
                color: #2A4A8B;

            }

            .login-btn{
                font-size:1rem;
                border:none;
                height:44px;
                width:40%;
                margin:20px 10px;
                border-radius:10px;
                background-color:#2A4A8B;
                font-family: 'Cairo', sans-serif;
                color:#ffffff
                
            }

            button:hover {
                box-shadow: 0px 0px 15px 0px rgb(0 0 0 / 30%);

            }

            /*Shrinking for mobile*/
            @media (max-width: 768px) {
                .form{
                    max-width:80vh;
                }
                .waves {
                    height:40px;
                    min-height:40px;
                }
                .content {
                    height:50vh;
                }
                h1 {
                    font-size:24px;
                }
                .inner-header img[data-v-ee8831f4] {
                    width: 20%;
                    position: relative;
                    margin-top: 6%;
                }
                input{
                    width: 60%;
                    
                } 
                .login-btn{
                width: 50%;
                margin: 10px 10px;
                }
            }

            @media (max-width: 516px) {
                .form{
                    max-width:50vh;
                }
                .waves {
                    height:40px;
                    min-height:40px;
                }
                .content {
                    height:50vh;
                }
                h1 {
                    font-size:24px;
                }
                .inner-header img[data-v-ee8831f4] {
                    width: 30%;
                    
                }
                input{
                    width: 90%;
                    
                }   
                .login-btn{
                width: 90%;
                margin: 10px 10px;
                }
                
            }
            @import url('https://fonts.googleapis.com/css?family=Roboto:100');
.body {
  background-color: #a7b6c685;
}

/** page **/
.cssloader {
  padding-top: calc(45vh - 25px);
  padding-bottom: calc(45vh - 40%);
}
@media (min-width:768px){
.cssloader{
padding-bottom: calc(45vh - 35%);
}
}
/** loader **/
.sh1 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 50px 50px 0 0;
  border-color: #026097  transparent transparent transparent;
  margin: 0 auto;
  animation: shk1 1s ease-in-out infinite normal;
}

.sh2 {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 50px 50px;
  border-color: transparent  transparent #71C6F7  transparent ;
  margin: -50px auto 0;
  animation: shk2 1s ease-in-out infinite alternate;
}

/** animation starts here **/
@keyframes shk1 {
  0% {
    transform: rotate(-360deg);
  }  
  
  100% {
  }
}

@keyframes shk2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
  }
}

.lt {
  color: #1e6667;
  font-family: 'Cairo', sans-serif;
  margin: 30px auto;
  text-align: center;
  font-weight: 100;
  
}
</style>